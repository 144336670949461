import React, { lazy } from 'react'

const PersonnelManagementListing = lazy(() =>
  import('./PersonnelModule/PersonnelManagementListing').then(module => ({
    default: module.PersonnelManagementListing,
  }))
)

const ModuleCompanyListing = lazy(() =>
  import('./ModuleCompanyListing').then(module => ({
    default: module.ModuleCompanyListing,
  }))
)


const CompanySetting = lazy(() =>
  import('./PersonnelModule/CompanySetting/CompanySetting').then(module => ({
    default: module.CompanySetting,
  }))
)

const TemplateLibraryAssignmentListing = lazy(() =>
  import(
    './PersonnelModule/CompanySetting/TemplateLibraryAssignment/TemplateLibraryAssignmentListing'
  ).then(module => ({
    default: module.TemplateLibraryAssignmentListing,
  }))
)

const ELetterAssignment = lazy(() =>
  import(
    './PersonnelModule/CompanySetting/E-LetterAssignment/E-LetterAssignment'
  ).then(module => ({
    default: module.ELetterAssignment,
  }))
)

const DataChangesApprovalPolicyAssignment = lazy(() =>
  import(
    './PersonnelModule/CompanySetting/DataChangesApprovalPolicyAssignment/DataChangesApprovalPolicyAssignment'
  ).then(module => ({
    default: module.DataChangesApprovalPolicyAssignment,
  }))
)

const PersonnelStandardELetteringTemplateListing = lazy(() =>
  import(
    './PersonnelModule/CompanySetting/E-LetterAssignment/PersonnelStandardELetteringTemplateListing'
  ).then(module => ({
    default: module.PersonnelStandardELetteringTemplateListing,
  }))
)

const PersonnelReportTemplateView = lazy(() =>
  import(
    './PersonnelModule/CompanySetting/E-LetterAssignment/PersonnelReportTemplateView'
  ).then(module => ({
    default: module.PersonnelReportTemplateView,
  }))
)

const CostCenterMapping = lazy(() =>
  import(
    './PersonnelModule/CompanySetting/CostCenterMapping/CostCenterMapping'
  ).then(module => ({
    default: module.CostCenterMapping,
  }))
)
const InsuranceSchemeCompanyForm = lazy(() =>
  import(
    './PersonnelModule/CompanySetting/InsuranceScheme/InsuranceSchemeCompanyForm'
  ).then(module => ({
    default: module.InsuranceSchemeCompanyForm,
  }))
)

const PersonnelRecruitmentSourceListing = lazy(() =>
  import(
    './PersonnelModule/CompanySetting/RecruitmentSource/RecruitmentSourceListing'
  ).then(module => ({
    default: module.RecruitmentSourceListing,
  }))
)

const PersonnelUserDefineFieldListing = lazy(() =>
  import(
    './PersonnelModule/CompanySetting/UserDefineField/UserDefineFieldListing'
  ).then(module => ({
    default: module.UserDefineFieldListing,
  }))
)

const EmployeeUserDefineFieldsListing = lazy(() =>
  import(
    './PersonnelModule/CompanySetting/EmployeeUserDefineFields/EmployeeUserDefineFieldsListing'
  ).then(module => ({
    default: module.EmployeeUserDefineFieldsListing,
  }))
)

const HomeRoutes = [
  {
    props: { exact: true, path: '/PersonnelManagementListing' },
    component: <PersonnelManagementListing />,
  },
  {
    props: { exact: true, path: '/ModuleCompanyListing' },
    component: <ModuleCompanyListing />,
  },

  {
    props: { exact: true, path: '/PersonnelModule/CompanySetting' },
    component: <CompanySetting />,
  },
  {
    props: {
      exact: true,
      path:
        '/PersonnelModule/CompanySetting/TemplateLibraryAssignment/TemplateLibraryAssignmentListing/:companyName',
    },
    component: <TemplateLibraryAssignmentListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/PersonnelModule/CompanySetting/E-LetterAssignment/E-LetterAssignment/:currentCompanyName',
    },
    component: <ELetterAssignment />,
  },
  {
    props: {
      exact: true,
      path:
        '/PersonnelModule/CompanySetting/DataChangesApprovalPolicyAssignment',
    },
    component: <DataChangesApprovalPolicyAssignment />,
  },
  {
    props: {
      exact: true,
      path:
        '/PersonnelModule/CompanySetting/E-LetterAssignment/E-LetterAssignment/TemplateLibraryAssignmentListing/:Category',
    },
    component: <PersonnelStandardELetteringTemplateListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/PersonnelModule/CompanySetting/E-LetterAssignment/E-LetterAssignment/PersonnelReportTemplateView/:Category',
    },
    component: <PersonnelReportTemplateView />,
  },
  {
    props: {
      exact: true,
      path:
        '/PersonnelModule/CompanySetting/CostCenterMapping/CostCenterMapping',
    },
    component: <CostCenterMapping />,
  },
  {
    props: {
      exact: true,
      path:
        '/PersonnelModule/CompanySetting/RecruitmentSource/RecruitmentSourceListing',
    },
    component: <PersonnelRecruitmentSourceListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/PersonnelModule/CompanySetting/UserDefineField/UserDefineFieldListing',
    },
    component: <PersonnelUserDefineFieldListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/PersonnelModule/CompanySetting/InsuranceScheme/InsuranceSchemeCompanyForm',
    },
    component: <InsuranceSchemeCompanyForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/PersonnelModule/CompanySetting/EmployeeUserDefineFields/EmployeeUserDefineFieldsListing',
    },
    component: <EmployeeUserDefineFieldsListing />,
  },
]

export default HomeRoutes
