// import authenticationRoutes from 'containers/Authentication/AuthenticationRoutes'
import authenticationRoutes from 'containers/AccountModule/AccountRoutes'
import dashboardRoutes from 'containers/DashboardModule/DashboardRoutes'
import React from 'react'
import { Redirect } from 'react-router-dom'
import logOutRoutes from 'containers/LogOutModule/LogOutRoutes'
import HomeRoutes from 'containers/HomeModule/HomeRoutes'
import PersonnelRoutes from 'containers/HomeModule/PersonnelModule/PersonnelRoutes'

import PersonnelEFileRoutes from 'containers/HomeModule/PersonnelModule/PersonnelEFile/PersonnelEFileRoutes'
import PersonnelEmployeeRoutes from 'containers/HomeModule/PersonnelModule/EmploymentInfo/PersonnelEmployeeRoutes'

import DigitalDocumentRoutes from 'containers/HomeModule/PersonnelModule/EmploymentInfo/EmployeeDigitalRecords/DigitalDocumentRoutes'


const StartRoute = [
  {
    props: { exact: true, path: '/' },
    component: <Redirect to="/" />,
  },
]

const errorRoute = [
  {
    // props: { exact: true, path: '/' },
    component: <Redirect to="/authentication/404" />,
  },
]

const routes = [
  ...HomeRoutes,
  ...PersonnelEmployeeRoutes,
  ...dashboardRoutes,

  ...PersonnelRoutes,
  ...PersonnelEmployeeRoutes,
 
  ...PersonnelEmployeeRoutes,
  ...DigitalDocumentRoutes,
 
  ...PersonnelEFileRoutes,

  ...logOutRoutes,
  ...authenticationRoutes,
  ...StartRoute,
  ...errorRoute,
]

export default routes
