import AppBar, { AppBarProps } from '@material-ui/core/AppBar'
import { Avatar, Breadcrumbs, useMediaQuery } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { useTheme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import SwapHoriz from '../../assets/hrIcons/switch.svg'
import CloseIcon from '@material-ui/icons/Close'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import MenuIcon from '@material-ui/icons/Menu'
import React from 'react'
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined'
import qrIcon from '../../assets/icons/qr-header.svg'
export interface MainHeaderProps {
  onClick?: any
  onClickAction?: any
  onClickRightBtn?: any
  routeSegments: any
  mainBtn: string
  sideBtn?: string
  title?: string
  smTitle?: string
  currency?: any
  rightRouteSegments?: any
  customIcon?: string
  rightLabel?: any
  position?: AppBarProps['position']
  dropDownOptions?: any
}
export const MainHeader = (props: MainHeaderProps) => {
  const {
    mainBtn,
    sideBtn,

    onClick,
    routeSegments,
    title,
    smTitle,
    onClickAction,
    onClickRightBtn,
    currency,
    rightRouteSegments,
    customIcon,
    position,
    rightLabel,
    dropDownOptions,
  } = props
  const theme = useTheme()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const handleButtonType = type => {
    let icon
    switch (type) {
      case 'menu':
        icon = <MenuIcon />
        break
      case 'back':
        icon = <KeyboardArrowLeftIcon />
        break
      case 'close':
        icon = <CloseIcon />
        break
      case 'exit':
        icon = <ExitToAppIcon className="transform-180" />
        break
      case 'customIcon':
        icon = <img src={customIcon} />
        break
      case 'image':
        icon = (
          <Avatar
            variant="square"
            alt="ifca"
            src={customIcon}
            style={{ width: '30px', height: '30px' }}
            imgProps={{
              loading: 'lazy',
            }}
          />
        )
        break

      default:
        icon = null
        break
    }
    return icon
  }

  const handleActionType = type => {
    let icon
    switch (type) {
      case 'switch':
        icon = (
          <img
            src={SwapHoriz}
            style={{ borderRadius: '50%', backgroundColor: 'white' }}
          />
        )
        break
      case 'checkIn':
        icon = <img src={qrIcon} />
        break
      case 'exit':
        icon = <ExitToAppIcon />
        break
      case 'dropdown':
        icon = <ArrowDropDownOutlinedIcon />
        break

      default:
        icon = null
        break
    }
    return icon
  }

  return (
    <AppBar
      id="main-header"
      className="mobile-header"
      position={position || 'fixed'}
    >
      <Toolbar>
        {/* {latestDesign ? (
          <div className="domain-new-container">
            {isDesktop && mainBtn === 'menu' ? null : (
              <IconButton className="first-btn" onClick={onClick}>
                {handleButtonType(mainBtn)}
              </IconButton>
            )}
            {mainBtn === 'customIcon' ? (
              <img
                style={{ marginRight: '0.5rem' }}
                src={customIcon}
                alt="icon"
              />
            ) : null}
            <div
              aria-label="dropdown-menu"
              className="domain-session  "
              onClick={onClickAction}
            >
              <div className="domain">{title}</div>
              <IconButton
                aria-label="dropdown-menu"
                className="second-btn"
                onClick={onClickAction}
              >
                {handleActionType(sideBtn)}
              </IconButton>
            </div>
          </div>
        ) : ( */}
        <div className="domain-container">
          {isDesktop && mainBtn === 'menu' ? null : (
            <IconButton className="first-btn" onClick={onClick}>
              {handleButtonType(mainBtn)}
            </IconButton>
          )}
          {mainBtn === 'customIcon' ? (
            <img
              style={{ marginRight: '0.5rem' }}
              src={customIcon}
              alt="icon"
            />
          ) : null}
          <div className="domain-session" onClick={onClickAction}>
            <div className="authorizer" style={{ lineHeight: '0.7rem' }}>
              {smTitle}
            </div>
            <div className="domain">{title}</div>
          </div>
          <div>
            {currency && <span className="currency">{currency}</span>}
            {rightLabel && <span className="">{rightLabel}</span>}
          </div>
          <IconButton className="second-btn" onClick={onClickRightBtn}>
            {handleActionType(sideBtn)}
          </IconButton>
        </div>
        {/* )} */}
        <div style={{ width: '100%', display: 'flex' }}>
          <Breadcrumbs
            className="breadcrumbs"
            separator="/"
            aria-label="breadcrumb"
          >
            {routeSegments.map((el, index) => {
              return (
                <span
                  className="breadcrumb-title"
                  key={index}
                  style={
                    {
                      // fontWeight: el.current ? 'bolder' : 'normal',
                      // lineHeight: '1rem',
                    }
                  }
                >
                  {' '}
                  {el.current ? el.name : '...'}
                </span>
              )
            })}
          </Breadcrumbs>
          {rightRouteSegments && (
            <Breadcrumbs
              className="breadcrumbs-right"
              separator="/"
              aria-label="breadcrumb"
            >
              {rightRouteSegments?.map((el, index) => {
                return (
                  <span
                    className="breadcrumb-title-right"
                    key={index}
                    // style={{ fontWeight: el.current ? 'bolder' : 'normal' }}
                  >
                    {' '}
                    {el.name}
                  </span>
                )
              })}
            </Breadcrumbs>
          )}
        </div>
        {dropDownOptions}
      </Toolbar>
    </AppBar>
  )
}
export default MainHeader
