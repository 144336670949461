import { Box, CircularProgress, Typography } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'

interface LoadingProps {
  backdropColor?: string
}

const useStyles = makeStyles<Theme, LoadingProps>((theme: Theme) =>
  createStyles({
    backdrop: props => ({
      zIndex: theme.zIndex.drawer + 1,
      color: props?.backdropColor || theme.palette.primary.main,
    }),
  })
)

export default function Loading({ backdropColor }: LoadingProps) {
  const classes = useStyles({ backdropColor })
  const [open, setOpen] = useState(false)
  useEffect(() => {
    setOpen(true)
  }, [open])
  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={open}
        transitionDuration={2000}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}
