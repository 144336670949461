import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useEmployeeLogoutMutation } from '../../generated/graphql'
import { setAccessToken } from 'AccessToken'

export async function FinalLogout() {
  let url = ''
  var prefix = ''

  switch (process.env.REACT_APP_API_URL) {
    case 'ifca-build':
      url = 'ifcahome.hrx.asia'
      prefix = 'https'

      break
    case 'prod-v5-build':
      url = 'hrms.hrx.asia'
      prefix = 'https'

      break
    case 'uat-v5-build':
      url = 'hrms-uat.hrx.asia'
      prefix = 'https'

      break
    case 'dev-v2-build':
      url = 'hrx-dev-employee.ifca.io'
      prefix = 'https'

      break
    case 'demo-build':
      url = 'hrms-demo.hrx.asia'
      prefix = 'https'
      break
    case 'prod-eon-build':
      url = 'employee.eon.com.my'
      prefix = 'https'
      break
    case 'uat-eon-build':
      url = 'employee-uat.eon.com.my'
      prefix = 'https'
      break
    default:
      url = 'localhost:3010'
      prefix = 'http'
  }

  window.location.href = `${prefix}://${url}/login`
}

export const Logout = () => {
  const history = useHistory()
  const [logout, { client }] = useEmployeeLogoutMutation()
  useEffect(() => {
    async function userLogout() {
      await logout()
      setAccessToken('')
      await client!.resetStore()
    }
    userLogout()
    FinalLogout()
  }, [])
  return <></>
}
