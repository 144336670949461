import DateFnsUtils from '@date-io/date-fns'
import DefaultAvatar from '@ifca-root/react-component/src/assets/images/default-avatar.png'
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview'
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents'
import { CommonDialog } from "@ifca-root/react-component/src/components/Dialog/CommonDialog"
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from "@ifca-root/react-component/src/components/Header/MainHeader"
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import TextSeparator from "@ifca-root/react-component/src/components/Typography/TextSeparator"
import { StyledMenu } from '@ifca-root/react-component/src/components/others/Menu'
import { useDropdownOption } from "@ifca-root/react-component/src/utils/hooks/useDropdownOption"
import { Avatar, Checkbox, FormControlLabel, IconButton, InputAdornment, ListItem, ListItemText, MenuItem, TextField } from "@material-ui/core"
import { Clear } from '@material-ui/icons'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Footer } from 'components/Footer/Footer'
import { InputUploadAllType } from 'components/Input/InputUploadAllType'
import { ContractDocType, DocumentType, HrPermission, Status, useCompanyHierarchyListQuery, useCompanyOrgUnitListQuery, useContractInfoEndMutation, useContractInfoInsertMutation, useContractInfoRenewalMutation, useContractInfoUpdateMutation, useGetCompanyPermissionQuery, useJobGradeByJobDesignationListingQuery, useJobPositionAssignmentListQuery } from "generated/graphql"
import React, { useEffect, useState } from "react"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { useHistory, useLocation } from "react-router"

interface IOrgUnit {
  id: string
  description: string
  level: Number
}
interface IJobPosition {
  id: string
  description: string
}
interface IPersonalInfoForm {
  ContractDocType: string
  DocNo: String
  StartDate: Date
  ExpiryDate: Date
  Remark: String
  EndRemark: string
  EndDate: Date
}

export const ContractDocumentExpiryForm = (props: any) => {
  let history = useHistory()
  let user = JSON.parse(localStorage.getItem('loggedInUser'))
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const { state }: any = useLocation()

  const mode = state?.TabValue === 0 ? 'dueExpiry' : 'existing';
  const [imagePreview, setImagePreview] = useState(
    mode === 'existing' && state?.States?.ContractInfo?.Document?.DocumentFile
        ? state?.States?.ContractInfo?.Document?.DocumentFile
        : null
  )
  const [selectedFileName, setSelectedFileName] = useState(
    mode === 'existing' && state?.States?.ContractInfo?.Document?.Description
        ? state?.States?.ContractInfo?.Document?.Description
        : null
  )
  const [selectedFile, setSelectedFile] = useState({
    files: [],
  })

  const [AddNewContract, setAddNewContract] = useState(false)
  const [EndRemarkRequired, setEndRemarkRequired] = useState(false)

  const [EmployeeStatusInput, setEmployeeStatusInput] = useState([])
  const [EmployeeTypeInput, setEmployeeTypeInput] = useState([])
  const [OrgUnitInput, setOrgUnitInput] = useState([])

  let dateFormat = require('dateformat')
  const now = new Date()

  console.log(state?.Contract)

  const {
    handleSubmit,
    errors,
    setError,
    clearErrors,
    control,
    setValue,
    getValues,
    watch,
    formState,
  } = useForm<IPersonalInfoForm>({
    defaultValues: {
      ContractDocType: state?.States?.ContractDocType,
      DocNo: mode === 'dueExpiry'
        ? ''
        : state?.States?.ContractInfo?.DocNo,
      StartDate: mode === 'dueExpiry'
        ? new Date(now.getFullYear(), now.getMonth(), now.getDate())
        : state?.States?.ContractInfo?.StartDate,
      ExpiryDate: mode === 'dueExpiry'
        ? new Date(now.getFullYear(), now.getMonth(), now.getDate())
        : state?.States?.ContractInfo?.ExpiryDate,
      Remark: mode === 'dueExpiry'
        ? ''
        : state?.States?.ContractInfo?.Remark,
      EndRemark: mode === 'dueExpiry'
        ? ''
        : state?.States?.ContractInfo?.EndRemark
          ? state?.States?.ContractInfo?.EndRemark
          : '',
      EndDate: mode === 'dueExpiry'
        ? state?.States?.ContractInfo?.ExpiryDate
        : state?.States?.ContractInfo?.EndDate
          ? state?.States?.ContractInfo?.EndDate
          : null,
    },
  })

  useEffect(() => {
    let isOverlapping = false

    if (state?.Contract?.length > 0) {
      for (const el of state?.Contract) {
        if (getValues('ContractDocType') === el?.ContractDocType.replace(/_/g, ' ')) {
          console.log(state?.States)
          if (
            state?.States?.ContractInfo?.ContractInfoID !== el?.ContractInfo?.ContractInfoID &&
            state?.States?.Employee?.EmployeeID === el?.Employee?.EmployeeID
          ) {
              const checkStartDateOverlap = 
                new Date(getValues('StartDate')).setHours(0, 0, 0, 0) >= new Date(el?.ContractInfo?.StartDate).setHours(0, 0, 0, 0) &&
                new Date(getValues('StartDate')).setHours(0, 0, 0, 0) <= new Date(el?.ContractInfo?.EndDate).setHours(0, 0, 0, 0);
              
              const checkExpiryDateOverlap = 
                new Date(getValues('ExpiryDate')).setHours(0, 0, 0, 0) >= new Date(el?.ContractInfo?.StartDate).setHours(0, 0, 0, 0) &&
                new Date(getValues('ExpiryDate')).setHours(0, 0, 0, 0) <= new Date(el?.ContractInfo?.EndDate).setHours(0, 0, 0, 0);
  
              console.log(checkStartDateOverlap)
              console.log(checkExpiryDateOverlap)
              if (checkStartDateOverlap) {
                setError('StartDate', {
                  type: 'custom',
                  message: 'Date chosen may overlap with existing contract'
                })
                isOverlapping = true
              }
              else {
                clearErrors('StartDate')
              }
  
              if (checkExpiryDateOverlap) {
                setError('ExpiryDate', {
                  type: 'custom',
                  message: 'Date chosen may overlap with existing contract'
                })
                
                isOverlapping = true
              }
              else {
                clearErrors('ExpiryDate')
              }
  
              if (isOverlapping) {
                return
              }
          }
        }
        else {
          clearErrors('ContractDocType')
        }
      }
    }
  }, [watch('StartDate'), watch('ExpiryDate'), watch('EndDate')])

  const { fields, append } = useFieldArray({
    control,
    name: 'OrgUnitItems',
  })

  const onChangeFile = event => {
    let nextState = { ...selectedFile }
    nextState.files.splice(0, nextState.files.length)
    nextState.files.push(event.target.files[0])
    setSelectedFileName(event.target.files[0]?.name)
    setSelectedFile(nextState)
    let reader = new FileReader()

    reader.onloadend = () => {
      setImagePreview(reader.result)
    }
    reader.readAsDataURL(event.target.files[0])
  }

  const snackBar = (message: string, redirect: boolean) => {
    setSnackBarMessage(message)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
      if (redirect) {
        history.goBack()
      }
    }, 1000)
  }

  const {
    data: { getCompanyPermission } = { getCompanyPermission: [] },
  } = useGetCompanyPermissionQuery({ fetchPolicy: 'no-cache' })
  let CompanyPermission: any = getCompanyPermission

  const snackFunc = (showText: string, redirect: boolean) => {
    setSnackBarMessage(showText)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
      // if (redirect === true) {
      //   history.push('/personnellisting/personnelefile', {
      //     employeeID: mode === 'New' ? empID : state?.EmployeeID,
      //     employeeName: empName,
      //     employee: state?.employee,
      //   })
      // }
    }, 1000)
  }

  const [ContractInfoRenewal] = useContractInfoRenewalMutation({
    onError: error => {
      snackFunc(error.message, false)
    },
    onCompleted: data => {
      if (data.ContractInfoRenewal === true) {
        snackFunc('Renew Successfully!', true)
        history.push('/contractdocumentexpiry/contractdocumentexpirylisting', {
          user: state?.state,
          SearchHistory: state?.SearchHistory,
          value: state?.TabValue,
          filterApply: state?.filterApply,
        })
      }
      else {
        snackFunc('Insert Fail!', false)
      }
    }
  })

  const [ContractInfoUpdate] = useContractInfoUpdateMutation({
    onError: error => {
      snackFunc(error.message, false)
    },
    onCompleted: data => {
      if (data.ContractInfoUpdate === true) {
        snackFunc('Updated Successfully!', true)
        !AddNewContract && state?.States?.Employee &&
          history.push('/contractdocumentexpiry/contractdocumentexpirylisting', {
            user: state?.state,
            SearchHistory: state?.SearchHistory,
            value: state?.TabValue,
            filterApply: state?.filterApply,
          })
      } else {
        snackFunc('Update Fail!', false)
      }
    },
  })

  const [ContractInfoEnd] = useContractInfoEndMutation({
    onError: error => {
      snackFunc(error.message, false)
    },
    onCompleted: data => {
      if (data.ContractInfoEnd) {
        snackFunc('Updated Successfully!', true)
        history.push('/contractdocumentexpiry/contractdocumentexpirylisting', {
          user: state?.state,
          SearchHistory: state?.SearchHistory,
          value: state?.TabValue,
          filterApply: state?.filterApply,
        })
      } else {
        snackFunc('Update Fail!', false)
      }
    },
  })

  let getOrgUnitList: IOrgUnit[] = []
  let getJobPosList: IJobPosition[] = []
  let getJobGradeList: IJobPosition[] = []
  const [OrgUnitItem, setOrgUnitItem] = useState(null)
  const [OrgStat, setOrgStat] = useState(false)
  const [JobPosItem, setJobPosItem] = useState(null)
  const [JobGradeItem, setJobGradeItem] = useState(null)
  const {
    data: { CompanyHierarchyList } = { CompanyHierarchyList: [] },
    loading: CompanyHierarchyListLoading,
  } = useCompanyHierarchyListQuery({
    variables: {
      CompanyID: user?.companyID,
    },
    fetchPolicy: 'network-only',
  })
  const {
    data: { CompanyOrgUnitList } = { CompanyOrgUnitList: [] },
    loading: CompanyOrgUnitListLoading,
  } = useCompanyOrgUnitListQuery({
    variables: {
      CompanyID: user?.companyID,
    },
    fetchPolicy: 'network-only',
  })
  const { data: JobGradeListing } = useJobGradeByJobDesignationListingQuery({
    variables: {
      JobDesignationID: JobPosItem?.id || '',
    },
    fetchPolicy: 'no-cache',
  })
  const {
    data: { JobPositionAssignmentList } = { JobPositionAssignmentList: [] },
    loading: JobPositionAssignmentListLoading,
  } = useJobPositionAssignmentListQuery({
    variables: {
      CompanyID: user?.companyID,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (mode === 'existing' && getValues('EndDate')) {
      setEndRemarkRequired(true)
    }
  }, [watch('EndDate')])

  const onSubmit = (data: IPersonalInfoForm) => {
    let ContractInfo: any = {}

    if (mode === 'dueExpiry') {
      if (AddNewContract) {
        let DocumentInput = null
        let DocumentID = null
        
        if (selectedFile.files.length > 0) {
          DocumentInput = {
            DocumentType: DocumentType.Employee,
            Attachment: selectedFile.files[0],
            Description: selectedFile.files[0] ? selectedFile.files[0].name : '',
            FileSize: selectedFile.files[0].size,
            Status: Status.Active,
          }
        }
        
        // let ContractInfoCurrent: any = {
        //   PersonnelID: state?.States?.Employee?.EmployeeID,
        //   ContractDocType: state?.States?.ContractDocType.replace(/ /g, '_'),
        //   DocNo: state?.States?.ContractInfo?.DocNo,
        //   StartDate: state?.States?.ContractInfo?.StartDate,
        //   ExpiryDate: state?.States?.ContractInfo?.ExpiryDate,
        //   Remark: state?.States?.ContractInfo?.Remark,
        //   DocumentInput: state?.States?.ContractInfo?.Document,
        //   DocumentID: state?.States?.ContractInfo?.DocumentID,
        //   EndDate: data?.EndDate,
        //   EndRemark: data?.EndRemark,
        // }

        ContractInfo = {
          PersonnelID: state?.States?.Employee?.EmployeeID,
          ContractDocType: state?.States?.ContractDocType.replace(/ /g, '_'),
          DocNo: data?.DocNo,
          StartDate: data?.StartDate,
          ExpiryDate: data?.ExpiryDate,
          Remark: data?.Remark,
          DocumentInput: DocumentInput,
          DocumentID: DocumentID,
          EndDate: null,
          EndRemark: null,
        }

        ContractInfoRenewal({
          variables: {
            ContractInfoID: state?.States?.ContractInfo?.ContractInfoID,
            // ContractInfoCurrent: ContractInfoCurrent,
            CurrentContractEndDate: data?.EndDate,
            CurrentContractEndRemark: data?.EndRemark,
            ContractInfoInput: ContractInfo,
          }
        })
      }
      else {
        ContractInfoEnd({
          variables: {
            ContractInfoID: state?.States?.ContractInfo?.ContractInfoID,
            CurrentContractEndDate: data?.EndDate,
            CurrentContractEndRemark: data?.EndRemark,
          },
        })
      }
    }
    else {
      let DocumentInput = null
      let DocumentID = null

      if (selectedFile.files.length > 0) {
        DocumentInput = {
          DocumentType: DocumentType.Employee,
          Attachment: selectedFile.files[0],
          Description: selectedFile.files[0] ? selectedFile.files[0].name : '',
          FileSize: selectedFile.files[0].size,
          Status: Status.Active,
        }
      }

      ContractInfo = {
        PersonnelID: state?.States?.Employee?.EmployeeID,
        ContractDocType: state?.States?.ContractDocType.replace(/ /g, '_'),
        DocNo: data?.DocNo,
        StartDate: data?.StartDate,
        ExpiryDate: data?.ExpiryDate,
        Remark: data?.Remark,
        DocumentInput: DocumentInput,
        DocumentID: DocumentID,
        EndRemark: data?.EndRemark,
        EndDate: data?.EndDate,
      }

      ContractInfoUpdate({
        variables: {
          ContractInfoInput: ContractInfo,
          ContractInfoID: state?.States?.ContractInfo?.ContractInfoID,
          IsRemovedAttachment: (DocumentID && imagePreview) ? false : true,
        },
      })
    }
  }

  const {
    anchorEl: dropanchorEL,
    handleClickListItem,
    handleClose,
  } = useDropdownOption()

  const requiredField = 'This field is required'

  const { isDirty } = formState
  const [openExitDialog, setopenExitDialog] = useState(false)

  return (
    <>
      <CommonDialog
        fullWidth={true}
        open={openExitDialog}
        onClose={() => setopenExitDialog(false)}
        sections={{
          header: {
            children: (
              <ListItem className="remove-padding">
                <ListItemText
                  primary={
                    <>
                      <span
                        className="smTitle c-darkblue flex-space"
                        style={{ color: 'orange' }}
                      >
                        Exit Confirmation
                      </span>
                    </>
                  }
                />
              </ListItem>
            ),
          },
          body: () => (
            <>
              <div>
                Are you sure you want to exit? Your changes will not be saved.
              </div>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setopenExitDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () =>
                    history.push('/contractdocumentexpiry/contractdocumentexpirylisting', {
                      user: state?.state,
                      SearchHistory: state?.SearchHistory,
                      value: state?.TabValue,
                      filterApply: state?.filterApply,
                    }),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />

      <MainHeader
        mainBtn="back"
        onClick={() => {
          if (isDirty) setopenExitDialog(true)
          else
            history.push('/contractdocumentexpiry/contractdocumentexpirylisting', {
              user: state?.state,
              SearchHistory: state?.SearchHistory,
              value: state?.TabValue,
              filterApply: state?.filterApply,
            })
        }}
        smTitle="Personnel Management"
        title={user?.companyName}
        routeSegments={[
          { name: 'Contract Document Expiry' },
          { name: 'Contract Document Expiry', current: true },
        ]}
      />

      <DynamicSubHeader
        handleClick={handleClickListItem}
        infoLine={
          <>
            <div className="extra-row">
              {state?.States?.ContractDocType}
            </div>
          </>
        }
        subInfoLine={
          <>
            <div className="">
              <span className="desc click-text" style={{ color: 'blue' }}>
                {/* {Employee?.Contact?.MobileNo} */}
              </span>
              <span className="desc">
                {dateFormat(
                  state?.States?.ContractInfo?.StartDate,
                  'dd mmm yyyy'
                )}
                {' - '}
                {dateFormat(
                  state?.States?.ContractInfo?.ExpiryDate,
                  'dd mmm yyyy'
                )}
              </span>
            </div>
          </>
        }
        avatar={
          <>
            <Avatar
              alt="chia"
              src={
                state?.States?.Employee?.Documents?.DocumentFile ||
                DefaultAvatar
              }
            />
            <div style={{ textAlign: 'center' }} className="desc">
              {state?.States?.Employee?.EmployeeNo}
            </div>
          </>
        }
        dropDownOptions={
          <StyledMenu
            id="customized-menu"
            anchorEl={dropanchorEL}
            keepMounted
            open={Boolean(dropanchorEL)}
            onClose={handleClose}
          >
            {' '}
            <ListItem
              onClick={() => {
                handleClose()
              }}
            >
              <ListItemText>
                <>
                  <div className="extra-row">
                    <span className="desc" style={{ color: 'grey' }}>
                      Company
                    </span>
                    <div>
                      <span className="mdDesc" style={{ paddingTop: '6px' }}>
                        {user?.companyName}
                      </span>
                    </div>
                  </div>

                  <div className="extra-row">
                    <span className="desc" style={{ color: 'grey' }}>
                      Org Unit
                    </span>
                    <div>
                      <span className="desc" style={{ paddingTop: '6px' }}>
                        {getOrgUnitList.filter(
                          e =>
                            e?.id ===
                            state?.States?.Employee?.OrgUnit?.CompanyOrgUnitID
                        ).length > 0
                          ? getOrgUnitList
                              .filter(
                                e =>
                                  e?.id ===
                                  state?.States?.Employee?.OrgUnit
                                    ?.CompanyOrgUnitID
                              )
                              .map(el => el?.description)
                          : 'Company'}
                      </span>
                    </div>
                  </div>

                  <div
                    className="extra-row"
                    style={{ paddingTop: '12px', paddingBottom: '12px' }}
                  >
                    <span
                      className="desc"
                      style={{ color: 'grey', width: '50%', float: 'left' }}
                    >
                      Job Position
                    </span>
                    <span
                      className="desc"
                      style={{ color: 'grey', width: '50%', float: 'left' }}
                    >
                      Job Grade
                    </span>
                    <div style={{ paddingBottom: '22px' }}>
                      <span
                        className="mdDesc"
                        style={{
                          paddingTop: '6px',
                          width: '50%',
                          float: 'left',
                        }}
                      >
                        {state?.States?.Employee?.Designation?.Name}
                      </span>
                      <span
                        className="mdDesc"
                        style={{
                          paddingTop: '6px',
                          width: '50%',
                          float: 'right',
                        }}
                      >
                        {state?.States?.Employee?.JobGrade?.Description}
                      </span>
                    </div>
                  </div>

                  <div
                    className="extra-row"
                    style={{ paddingTop: '12px', paddingBottom: '12px' }}
                  >
                    <span
                      className="desc"
                      style={{ color: 'grey', width: '50%', float: 'left' }}
                    >
                      Join Date
                    </span>
                    <span
                      className="desc"
                      style={{ color: 'grey', width: '50%', float: 'left' }}
                    >
                      Join Reference Date
                    </span>
                    <div style={{ paddingBottom: '22px' }}>
                      <span
                        className="mdDesc"
                        style={{
                          paddingTop: '6px',
                          width: '50%',
                          float: 'left',
                        }}
                      >
                        {' '}
                        {dateFormat(
                          state?.States?.Employee?.JoinedDate,
                          'dd mmm yyyy'
                        )}
                      </span>
                      <span
                        className="mdDesc"
                        style={{
                          paddingTop: '6px',
                          width: '50%',
                          float: 'right',
                        }}
                      >
                        {' '}
                        {dateFormat(
                          state?.States?.Employee?.JoinedReferenceDate,
                          'dd mmm yyyy'
                        )}
                      </span>
                    </div>
                  </div>

                  <div
                    className="extra-row"
                    style={{ paddingTop: '12px', paddingBottom: '12px' }}
                  >
                    <span
                      className="desc"
                      style={{ color: 'grey', width: '50%', float: 'left' }}
                    >
                      Probation Month
                    </span>
                    <span
                      className="desc"
                      style={{ color: 'grey', width: '50%', float: 'left' }}
                    >
                      Confirmation Due Date
                    </span>
                    <div style={{ paddingBottom: '22px' }}>
                      <span
                        className="mdDesc"
                        style={{
                          paddingTop: '6px',
                          width: '50%',
                          float: 'left',
                        }}
                      >
                        {' '}
                        {state?.States?.Employee?.Probation}
                      </span>
                      <span
                        className="mdDesc"
                        style={{
                          paddingTop: '6px',
                          width: '50%',
                          float: 'right',
                        }}
                      >
                        {dateFormat(
                          state?.States?.Employee?.ConfirmedDate,
                          'dd mmm yyyy'
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="extra-row">
                    <span className="desc" style={{ color: 'grey' }}>
                      Superior
                    </span>
                    <div>
                      <span className="mdDesc" style={{ paddingTop: '6px' }}>
                        {' '}
                        {
                          state?.States?.Employee?.Supervisor?.Contact?.FullName
                        }{' '}
                        | {state?.States?.Employee?.Supervisor?.EmployeeNo}
                      </span>
                    </div>
                  </div>

                  <div
                    className="extra-row"
                    style={{ paddingTop: '12px', paddingBottom: '12px' }}
                  >
                    <span
                      className="desc"
                      style={{ color: 'grey', width: '50%', float: 'left' }}
                    >
                      Employee Status
                    </span>
                    <span
                      className="desc"
                      style={{ color: 'grey', width: '50%', float: 'left' }}
                    >
                      Employment Status
                    </span>
                    <div style={{ paddingBottom: '22px' }}>
                      <span
                        className="mdDesc"
                        style={{
                          paddingTop: '6px',
                          width: '50%',
                          float: 'left',
                        }}
                      >
                        {' '}
                        {state?.States?.Employee?.Status}
                      </span>
                      <span
                        className="mdDesc"
                        style={{
                          paddingTop: '6px',
                          width: '50%',
                          float: 'right',
                        }}
                      >
                        {' '}
                        {state?.States?.Employee?.EmploymentType}
                      </span>
                    </div>
                  </div>
                </>
              </ListItemText>
            </ListItem>
          </StyledMenu>
        }
        title={`
          ${state?.States?.Employee?.Contact?.FullName} | ${state?.States?.Employee?.Designation?.Name}
        `}
      />

      <ContentWrapper multiDynamicInfo footer>
        {mode === 'dueExpiry' && (
          <>
            <CardContents style={{ marginTop: '12px' }}>
              <Controller
                name="EndDate"
                control={control}
                render={({ onChange, value, name }) => (
                  <KeyboardDatePicker
                    helperText={''}
                    error={false}
                    // InputProps={{ readOnly: true }}
                    // rifmFormatter={dateFormatter}
                    label="End Date"
                    format="dd/MM/yyyy"
                    onChange={date => [onChange(date)]}
                    value={value}
                    name={name}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    minDate={value}
                    required={mode === 'dueExpiry'}
                  />
                )}
              />

              <Controller
                as={TextField}
                required={mode === 'dueExpiry'}
                control={control}
                label="End Remark"
                name="EndRemark"
                rules={{
                  required: { value: true, message: requiredField },
                }}
                helperText={errors?.EndRemark?.['message']}
                error={
                  errors?.EndRemark && errors?.EndRemark?.['type'] === 'required'
                    ? true
                    : false
                }
              />
              {/* <Controller
                name="ActionRemark"
                label="Remark"
                margin="normal"
                fullWidth
                ref={register}
                control={control}
                render={({ value, onChange }) => (
                  <TextField
                    name="ActionRemark"
                    ref={register}
                    value={value}
                    onChange={onChange}
                    label="Remark"
                    required
                    fullWidth
                    margin="normal"
                    autoComplete="off"
                  />
                )}
              /> */}
            </CardContents>

            <Controller
              control={control}
              name="AddNewContract"
              render={({ value, onChange }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={'AddNewContract'}
                      checked={AddNewContract}
                      onChange={e => setAddNewContract(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Add New Contract"
                />
              )}
              defaultValue={false}
            />
          </>
        )}
        
        <div style={{ 
            display: AddNewContract || mode === 'existing' ? 'block' : 'none' 
          }}
        >
          <CardContents  style={{ marginTop: mode === 'existing' && '12px' }}>
            <Controller
              as={
                <TextField 
                  required 
                  name={'ContractDocType'}
                  disabled
                  value={state?.States?.ContractDocType}
                >
                  {state?.States?.ContractDocType}
                </TextField>
              }
              required={AddNewContract || mode === 'existing'}
              control={control}
              label="Document Type"
              name="ContractDocType"
            />

            <Controller
              as={TextField}
              required={AddNewContract || mode === 'existing'}
              control={control}
              label="Doc No."
              name="DocNo"
              rules={{
                required: { 
                  value: AddNewContract || mode === 'existing', 
                  message: requiredField 
                },
              }}
              helperText={errors?.DocNo?.['message']}
              error={
                errors?.DocNo && errors?.DocNo?.['type'] === 'required'
                  ? true
                  : false
              }
            />

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                name="StartDate"
                control={control}
                render={({ onChange, value, name }) => (
                  <KeyboardDatePicker
                    required={AddNewContract || mode === 'existing'}
                    helperText={errors?.StartDate?.['message']}
                    error={!!errors?.StartDate}
                    // InputProps={{ readOnly: true }}
                    // rifmFormatter={dateFormatter}
                    label="Start Date"
                    format="dd/MM/yyyy"
                    onChange={date => [onChange(date)]}
                    value={value}
                    className="left"
                    name={name}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                )}
                defaultValue={new Date()}
                rules={{
                  required: { value: true, message: requiredField },
                }}
                helperText={errors?.StartDate?.['message']}
                error={
                  errors?.StartDate && 
                  (errors?.StartDate?.['type'] === 'required' ||
                  errors?.StartDate?.['type'] === 'custom')
                    ? true
                    : false
                }
              />
            </MuiPickersUtilsProvider>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Controller
                name="ExpiryDate"
                control={control}
                render={({ onChange, value, name }) => (
                  <KeyboardDatePicker
                    required={AddNewContract || mode === 'existing'}
                    helperText={errors?.ExpiryDate?.['message']}
                    error={!!errors?.ExpiryDate}
                    // InputProps={{ readOnly: true }}
                    // rifmFormatter={dateFormatter}
                    label="Expiry Date"
                    format="dd/MM/yyyy"
                    onChange={date => [onChange(date)]}
                    value={value}
                    className="right"
                    name={name}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                )}
                defaultValue={new Date()}
                rules={{
                  required: { value: true, message: requiredField },
                }}
                helperText={errors?.ExpiryDate?.['message']}
                error={
                  errors?.ExpiryDate && 
                  (errors?.ExpiryDate?.['type'] === 'required' ||
                  errors?.ExpiryDate?.['type'] === 'custom')
                    ? true
                    : false
                }
              />
            </MuiPickersUtilsProvider>

            {mode === 'existing' && (
              <>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    required
                    name="EndDate"
                    control={control}
                    render={({ onChange, value, name }) => (
                      <KeyboardDatePicker
                        clearable
                        helperText={''}
                        error={false}
                        InputProps={{ 
                          readOnly: true,
                          startAdornment:
                            value != null ? (
                              <InputAdornment
                                position="start"
                                style={{
                                  position: 'absolute',
                                  right: '40px',
                                }}
                              >
                                <IconButton
                                  onClick={() => {
                                    setValue('EndDate', null)
                                    setEndRemarkRequired(false)
                                  }}
                                >
                                  <Clear />
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                        }}
                        label="End Date"
                        format="dd/MM/yyyy"
                        onChange={date => [onChange(date)]}
                        value={value}
                        className="left"
                        name={name}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        minDate={getValues('StartDate')}
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
  
                <Controller
                  as={TextField}
                  required={EndRemarkRequired}
                  control={control}
                  label="End Remark"
                  name="EndRemark"
                  className="right"
                  InputProps={{
                    startAdornment:
                      watch('EndRemark') !== '' ? (
                        <InputAdornment
                          position="end"
                          style={{
                            position: 'absolute',
                            right: '0',
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              setValue('EndRemark', '')
                            }}
                          >
                            <Clear />
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                  }}
                  rules={{
                    required: { value: EndRemarkRequired, message: requiredField },
                  }}
                  />
              </>
            )}

            <InputUploadAllType
              value={selectedFileName}
              label="Attachment"
              handleUpload={e => onChangeFile(e)}
              className="p-b-20"
              fullWidth
            />
            {imagePreview !== null ? (
              <div className="content-wrap full">
                <UploadPreview
                  src={imagePreview}
                  mediaType={imagePreview.includes('pdf') ? 'pdf' : 'image'}
                  onClick={() => {
                    setSelectedFile({ files: [] })
                    setSelectedFileName('')
                    setImagePreview(null)
                  }}
                  remove
                />
              </div>
            ) : null}
            <Controller
              as={TextField}
              // required
              control={control}
              label="Remark"
              name="Remark"
              // rules={{
              //   required: { value: true, message: requiredField },
              // }}
            />
          </CardContents>
        </div>
      </ContentWrapper>

      <Footer
        options={[
          {
            name: 'Save',
            onClick: () => {
              handleSubmit(onSubmit)()
            },
            color: 'primary',
            // props: {
            //   type: 'submit',
            // },
            disabled:
              user?.superUser ||
              CompanyPermission[user?.companyID]?.hasOwnProperty(
                HrPermission.HomePersonnelEmployeeInfoContractDocumentCreate,
                HrPermission.HomePersonnelEmployeeInfoContractDocumentUpdate
              )
                ? false
                : true,
          },
        ]}
      />

      <SnackBarMsg open={openSnackBar} message={snackBarMessage} />
    </>
  )
}