import React, { lazy } from 'react'

const PersonnelEFile = lazy(() =>
  import('./PersonnelEFileSubmenu').then(module => ({
    default: module.PersonnelEFileSubmenu,
  }))
)
const PersonnelProfileForm = lazy(() =>
  import('../PersonnelEFile/PersonnelProfile/PersonnelProfileForm').then(
    module => ({
      default: module.PersonnelProfileForm,
    })
  )
)
const PersonalDocument = lazy(() =>
  import('./PersonalDocument/PersonalDocumentListing').then(module => ({
    default: module.PersonalDocumentListing,
  }))
)

const CertificateListing = lazy(() =>
  import('../PersonnelEFile/PersonnelCertificate/CertificateListing').then(
    module => ({
      default: module.CertificateListing,
    })
  )
)

const CertificateForm = lazy(() =>
  import('../PersonnelEFile/PersonnelCertificate/CertificateForm').then(
    module => ({
      default: module.CertificateForm,
    })
  )
)

const HealthInfoForm = lazy(() =>
  import('../PersonnelEFile/PersonnelHealthInfo/HealthInfoForm').then(
    module => ({
      default: module.HealthInfoForm,
    })
  )
)

const DependentListing = lazy(() =>
  import('./Dependent/DependentListing').then(module => ({
    default: module.DependentListing,
  }))
)

const DependentForm = lazy(() =>
  import('./Dependent/DependentForm').then(module => ({
    default: module.DependentForm,
  }))
)

const EducationList = lazy(() =>
  import('././Education/EducationList').then(module => ({
    default: module.EducationList,
  }))
)

const EducationForm = lazy(() =>
  import('././Education/EducationForm').then(module => ({
    default: module.EducationForm,
  }))
)
const JobHistoryList = lazy(() =>
  import('././JobHistory/JobHistoryList').then(module => ({
    default: module.JobHistoryList,
  }))
)

const JobHistoryForm = lazy(() =>
  import('././JobHistory/JobHistoryForm').then(module => ({
    default: module.JobHistoryForm,
  }))
)

const ReferenceListing = lazy(() =>
  import('./Reference/ReferenceListing').then(module => ({
    default: module.ReferenceListing,
  }))
)

const ReferenceForm = lazy(() =>
  import('./Reference/ReferenceForm').then(module => ({
    default: module.ReferenceForm,
  }))
)

// const PersonnelUserDefineForm = lazy(() =>
//   import('./PersonnelUserDefine/PersonnelUserDefineForm.txt').then(module => ({
//     default: module.PersonnelUserDefineForm,
//   }))
// )

const PersonnelUserDefineFieldsForm = lazy(() =>
  import('./PersonnelUserDefine/PersonnelUserDefineFieldsForm').then(
    module => ({
      default: module.PersonnelUserDefineFieldsForm,
    })
  )
)

const PersonnelEFileRoutes = [
  {
    props: {
      exact: true,
      path: '/personnellisting/personnelefile/personaldocument',
    },
    component: <PersonalDocument />,
  },
  {
    props: { exact: true, path: '/personnellisting/personnelefile' },
    component: <PersonnelEFile />,
  },
  {
    props: {
      exact: true,
      path: '/personnellisting/personnelefile/personnelprofile',
    },
    component: <PersonnelProfileForm />,
  },
  {
    props: {
      exact: true,
      path: '/personnellisting/personnelefile/personnelcertificate',
    },
    component: <CertificateListing />,
  },
  {
    props: {
      exact: true,
      path: '/personnellisting/personnelefile/personnelcertificateform',
    },
    component: <CertificateForm />,
  },
  {
    props: {
      exact: true,
      path: '/personnellisting/personnelefile/personnelcertificateform/add',
    },
    component: <CertificateForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/personnellisting/personnelefile/personnelcertificateform/edit',
    },
    component: <CertificateForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/personnellisting/personnelefile/personnelhealthinfo',
    },
    component: <HealthInfoForm />,
  },
  {
    props: {
      exact: true,
      path: '/personnelefile/dependent/dependentlisting',
    },
    component: <DependentListing />,
  },
  {
    props: {
      exact: true,
      path: '/personnelefile/dependent/dependentform/add',
    },
    component: <DependentForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/personnelefile/dependent/dependentform/edit',
    },
    component: <DependentForm mode="edit" />,
  },
  {
    props: { exact: true, path: '/personnellisting/personnelefile/education' },
    component: <EducationList />,
  },
  {
    props: { exact: true, path: '/personnellisting/personnelefile/jobhistory' },
    component: <JobHistoryList />,
  },
  {
    props: {
      exact: true,
      path: '/personnellisting/personnelefile/educationform',
    },
    component: <EducationForm />,
  },
  {
    props: {
      exact: true,
      path: '/personnellisting/personnelefile/jobhistoryform',
    },
    component: <JobHistoryForm />,
  },
  {
    props: {
      exact: true,
      path: '/personnelefile/reference/referencelisting',
    },
    component: <ReferenceListing />,
  },
  {
    props: {
      exact: true,
      path: '/personnelefile/reference/referenceform/add',
    },
    component: <ReferenceForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/personnelefile/reference/referenceform/edit',
    },
    component: <ReferenceForm mode="edit" />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path: '/personnelefile/userdefinefield',
  //   },
  //   component: <PersonnelUserDefineForm  />,
  // },
  {
    props: {
      exact: true,
      path: '/personnelefile/peronneluserdefinefields/add',
    },
    component: <PersonnelUserDefineFieldsForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/personnelefile/peronneluserdefinefields/edit',
    },
    component: <PersonnelUserDefineFieldsForm mode="edit" />,
  },
]

export default PersonnelEFileRoutes
